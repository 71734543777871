import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

import Intent from "../../components/common/link";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const FirstSlider = (props) => {
    const { data } = props || [];
    const [allData, setAllData] = useState({});
    
    useEffect(() => {
        if(data.Contents) {
            let IMG = data.Contents.find(item => item.Key === "home-first-slider-background").Value;
            let Label = data.Contents.find(item => item.Key === "home-first-slider-label").Value;
            let Title = data.Contents.find(item => item.Key === "home-first-slider-title").Value;
            let Text = data.Contents.find(item => item.Key === "home-first-slider-text").Value;             
            setAllData({...data, IMG, Label, Title, Text});
        }
    }, [data])

    return (
        <Slider {...settings} className="slider__one" style={{background: `url(${allData.IMG}) no-repeat center/cover`}}>
            <div className="slider__item">
                <div>
                    <p className="slider__label">{allData.Label}</p>
                    <h1 className="slider__title">{allData.Title}</h1>
                    <p className="slider__paragraph">{allData.Text}</p>
                    <div>
                        <Intent to="/sobre" classes="primary-button white" >SAIBA MAIS</Intent>
                        <Intent to="/tecnologia" classes="primary-button reverse white">SERVIÇOS</Intent>
                    </div>
                </div>
            </div>
            <div className="slider__item">
                <div>
                    <p className="slider__label">{allData.Label}</p>
                    <h1 className="slider__title">{allData.Title}</h1>
                    <p className="slider__paragraph">{allData.Text}</p>
                    <div>
                        <Intent to="/sobre" classes="primary-button white">SAIBA MAIS</Intent>
                        <Intent to="/tecnologia" classes="primary-button reverse white">SERVIÇOS</Intent>
                    </div>
                </div>
            </div>
            <div className="slider__item">
                <div>
                    <p className="slider__label">{allData.Label}</p>
                    <h1 className="slider__title">{allData.Title}</h1>
                    <p className="slider__paragraph">{allData.Text}</p>
                    <div>
                        <Intent to="/sobre" classes="primary-button white">SAIBA MAIS</Intent>
                        <Intent to="/tecnologia" classes="primary-button reverse white">SERVIÇOS</Intent>
                    </div>
                </div>
            </div>
        </Slider>
    )
}

export default FirstSlider;
